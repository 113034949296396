<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <span>Analyses des charges</span>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Analyses des charges
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <!-- <span> <span class="text-info"> Analyses des charges  &rarr;  </span>  La repartition de ce montant par catégorie de charges</span>  -->
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <ul class="nav nav-tabs"
        id="myTab"
        role="tablist">
      <li class="nav-item">
        <a class="nav-link active"
           id="vue-tab"
           data-toggle="tab"
           href="#vue"
           role="tab"
           aria-controls="home"
           aria-selected="true"
           @click="selection('vue-globale')">Vue Globale</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="decompte-tab"
           data-toggle="tab"
           href="#decompte"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="selection('decompte')">Décomptes</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="engagement-tab"
           data-toggle="tab"
           href="#engagement"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="selection('engagement')">Engagement Financier</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="etat-tab"
           data-toggle="tab"
           href="#etat"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="selection('etat')">Situation des emplois du FER du {{ periode }}</a>
      </li>
    </ul>
    <!--les dives -->
    <div class="tab-content"
         id="myTabContent">
      <!-- vue -->
      <div class="tab-pane fade show active"
           id="vue"
           role="tabpanel"
           aria-labelledby="home-tab">
        <VueGlobal :extYear="year"
                   v-if="choix=='vue-globale'"/>
      </div>

      <div class="tab-pane fade "
           id="decompte"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <Decomptes @annee="annees => (year = annees)"
                   :extYear="year"
                   v-if="choix=='decompte'"/>
      </div>

      <div class="tab-pane fade "
           id="engagement"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <EngagementFinanciere :extYear="year"
                              v-if="choix=='engagement'"/>
      </div>
      <div class="tab-pane fade "
           id="etat"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <EtatDesDecaissables :extYear="year"
                             v-if="choix=='etat'"/>
      </div>

    </div>
  </div>
</template>
<style>
@import './operationsBancaires.css';
/* .table tbody td,
.table tfoot td,
.table thead th{
  font-size: 0.54em;
}
form.form-inline input,
li.nav-item,
form.form-inline button{
  font-size: 0.6em;
}
form.form-inline input{
  height: fit-content;
} */
</style>
<script>
import Decomptes from './charges/Decomptes.vue'
import VueGlobal from './charges/VueGlobal.vue'
import EngagementFinanciere from './charges/EngagementFinanciere.vue'
import EtatDesDecaissables from './charges/EtatDecaissable.vue'

export default {
  name : "Charges",
  components:{
    Decomptes,
    VueGlobal,
    EngagementFinanciere,
    EtatDesDecaissables
  },
  data:()=>({
    year:{
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",  
    },
    periode:"01-01 au 31-12 " + new Date().getFullYear(),
    choix:"vue-globale",
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
  }),
  watch:{
    annee_fin(){
      console.log(this.annee_fin)
    }
  },
  computed:{},
  methods:{
    changeYear(){
      this.year.annee_debut=this.annee_debut
      this.year.annee_fin=this.annee_fin
      console.log(this.annee_fin)
      var split_annee_debut=this.annee_debut.split("-")
      var split_annee_fin=this.annee_fin.split("-")
      if (split_annee_debut[0] == split_annee_fin[0]) {
        this.periode= split_annee_debut[2]+"-"+split_annee_debut[1]+" au "+split_annee_fin[2]+"-"+split_annee_fin[1]+"-"+split_annee_fin[0]
      }else{
        this.periode= split_annee_debut[2]+"-"+split_annee_debut[1]+"-"+split_annee_debut[0]+" au "+split_annee_fin[2]+"-"+split_annee_fin[1]+"-"+split_annee_fin[0]
      }
      var choice=this.choix
      this.choix=""
      setTimeout(
        function() {
          this.choix=choice
        }.bind(this),
        300
      )
    },
    selection(choix){
      this.choix=""
      setTimeout(
        function() {
          this.choix=choix
        }.bind(this),
        300
      )
    }
  }
}
</script>

<template>
  <div>
    <div class="col-md-7 mx-auto text-center pt-5"
         v-if="this.chartData.datasets[0].data.length !==0">
      <Pie :chartData="chartData"
           :height="270"></Pie>
    </div>
    <div class="col-md-7 mx-auto text-center pt-5 text-uppercase card mt-2 title"
         v-else>
      <p>Aucune donnée disponible!</p>
    </div>
  </div>
</template>
<script>
import Pie from "../recettes/PieChart"
import {mapActions, mapGetters, mapMutations} from "vuex"

export default {
  name: "Graphique",
  props:["year"],
  components: {
    Pie
  },
  data: () => ({
    donneesGraphique:[],
    tableacuCouleur:["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)", "rgb(57, 158, 101)", "rgb(39, 29, 107)", "rgb(107, 29, 89)", "rgb(97, 39, 99)"],
    chartData: {
      labels: [],
      datasets: [
        {
          label: "Recette péage (%)",
          data: [],
          backgroundColor: [],
          hoverOffset: 4
        }
      ]
    },
    annee: {
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
      periode:"MENSUELLE",  
      base:"CONSOLIDEE"  
    },
    totaux:{
      per:0,
      peage:0,
      pesage:0,
      fonctionnement:0,
      sous_total:0,
      general:0,
    }
  }),
  watch:{
    TableauGraphique(){
      this.chartData.datasets[0].data=[]
      this.donneesGraphique = this.TableauGraphique.donnees
      this.addColor(6)
      if(this.donneesGraphique[0]){
        this.donneesGraphique.forEach(charges =>{
          this.chartData.datasets[0].data.push(Math.round(Number(charges.per_decompte_recu)))
          this.chartData.datasets[0].data.push(Math.round(Number(charges.depense_pesage)))
          this.chartData.datasets[0].data.push(Math.round(Number(charges.depense_peage)))
          this.chartData.datasets[0].data.push(Math.round(Number(charges.autres_depense_assimiles)))
          this.chartData.datasets[0].data.push(Math.round(Number(charges.fonctionnement)))
          this.chartData.datasets[0].data.push(Math.round(Number(charges.remboursement)))
        })
      }else{
        this.chartData.datasets[0].data=[]
      }
    },
    etatDecaissable(){
      if (this.etatDecaissable) {
        this.chartData.datasets[0].data=[]
        this.chartData.labels=[]

        // console.log(this.etatDecaissable)
        if(this.annee.periode=="MENSUELLE"){
          var fin_annee=this.annee.annee_fin.split("-")
          var debut_annee=this.annee.annee_debut.split("-")
          this.etatMensuel=[]
          var count=0
          this.etatDecaissable.niveau_3.forEach(niveau3 => {
            this.chartData.labels.push(niveau3.libelle)
            this.etatMensuel.push({libelle:niveau3.libelle,niveau_4:[],total_n3:[]})
            var counter=0
            niveau3.niveau_4.forEach(element => {
              this.etatMensuel[count].niveau_4.push({libelle:element.libelle,montants:[]})
              var total_n4=0
              for (let index = debut_annee[1]-1; index < fin_annee[1]; index++) {
                this.etatMensuel[count].niveau_4[counter].montants.push(element.montants[0][index])
                total_n4+=element.montants[0][index]
              }
              this.etatMensuel[count].niveau_4[counter].montants.push(total_n4)
              counter++
            })
            for (let index = 0; index < this.etatMensuel[count].niveau_4[0].montants.length; index++) {
              this.etatMensuel[count].total_n3.push(0)
            }
            this.etatMensuel[count].niveau_4.forEach(niveau4 => {
              for (let index = 0; index < niveau4.montants.length; index++) {
                this.etatMensuel[count].total_n3[index]+=niveau4.montants[index]
                                
              }
            })
            count++
          })
          for (let index = 0; index < this.chartData.labels.length; index++) {
            this.chartData.datasets[0].data.push(this.etatMensuel[index].total_n3[this.etatMensuel[index].total_n3.length-1])
          }
          console.log(this.chartData)
        }else{
          this.etatAnnuel=[]
          count=0
          this.etatDecaissable.niveau_3.forEach(niveau3 => {
            this.chartData.labels.push(niveau3.libelle)
            
            this.etatAnnuel.push({libelle:niveau3.libelle,niveau_4:[],total_n3:[],periode:""})
            var counter=0
            niveau3.niveau_4.forEach(element => {
              this.etatAnnuel[count].niveau_4.push({libelle:element.libelle,montants:[]})
              this.etatAnnuel[count].periode = element.annees[0]
              var total_n4=0
              for (let index = 0; index < element.montants[0].length; index++) {
                this.etatAnnuel[count].niveau_4[counter].montants.push(element.montants[0][index])
                total_n4+=element.montants[0][index]
              }
              this.etatAnnuel[count].niveau_4[counter].montants.push(total_n4)
              counter++
            })
            for (let index = 0; index < this.etatAnnuel[count].niveau_4[0].montants.length; index++) {
              this.etatAnnuel[count].total_n3.push(0)
            }
            this.etatAnnuel[count].niveau_4.forEach(niveau4 => {
              for (let index = 0; index < niveau4.montants.length; index++) {
                this.etatAnnuel[count].total_n3[index]+=niveau4.montants[index]                
              }
              this.chartData.datasets[0].data.push(this.etatAnnuel[count].total_n3[niveau4.montants.length-1])
            })
            count++
          })
          console.log(this.chartData)
        }
        this.addColor(this.chartData.datasets[0].data.length)
      }
        
    },
    year(){
      this.chartData.datasets[0].data=[]
      // this.getTableauGraphique(this.year)
      this.loadEtatDecaissables(this.annee)

    },
  },
  created() {
    this.annee.annee_debut=this.year.annee_debut
    this.annee.annee_fin=this.year.annee_fin
    var fin_annee=this.year.annee_fin.split("-")
    var debut_annee=this.year.annee_debut.split("-")
    if (fin_annee[0]==debut_annee[0]) {
      this.annee.periode="MENSUELLE"
    }else{
      this.annee.periode="ANNUELLE"
    }
    // this.getTableauGraphique(this.year)
    this.loadEtatDecaissables(this.annee)
  },
  computed:{
    ...mapGetters(["TableauGraphique","etatDecaissable","msgFailEtatDecaissable"])
  },
  methods:{
    ...mapActions(["getTableauGraphique","loadEtatDecaissables"]),
    ...mapMutations(["setEtatDecaisssable","setMsgFailEtatDecaisssable"]),
    addColor(long){
      var tableau=0
      for (let i = 0; i < long; i++) {
        if (i > this.tableacuCouleur.length) {
          tableau=0
        }
        this.chartData.datasets[0].backgroundColor.push(this.tableacuCouleur[tableau])
        tableau++
      }
    }
  }

}
</script>

<template>
  <div class="mx-auto mt-2">
    <table class="table table-blue"> 
      <thead>
        <tr>
          <!-- <th class="th-blue"></th> -->
          <th class="tht-dark-blue"
              scope="col"
              rowspan="2"
              style="vertical-align: middle;border-right: 2px solid #dbdbdb">Programme</th>
          <th class="tht-dark-blue text-center"
              scope="col"
              colspan="2"
              style="border-right: 2px solid #dbdbdb">Stock antérieur (FCFA)</th>
          <th class="tht-dark-blue text-right"
              scope="col"
              rowspan="2"
              style="vertical-align: middle;border-right: 2px solid #dbdbdb">Décomptes reçus (FCFA)</th>
          <th class="tht-dark-blue text-right"
              scope="col"
              rowspan="2"
              style="vertical-align: middle;border-right: 2px solid #dbdbdb">Total décomptes à payer (FCFA)</th>
          <th class="tht-dark-blue text-center"
              scope="col"
              colspan="3"
              style="border-right: 2px solid #dbdbdb">Décomptes payés (FCFA)</th>
          <th class="tht-dark-blue text-center"
              scope="col"
              colspan="3">Décomptes en attente de paiement (FCFA)</th>
          
        </tr>
        <tr>
          <th class="th-blue text-right">N-1</th>
          <th class="th-blue text-right"
              style="border-right: 2px solid #dbdbdb">N</th>
          <th class="th-blue text-right">Dcptes antérieurs</th>
          <th class="th-blue text-right">Dcptes reçus de la période</th>
          <th class="th-blue text-right"
              style="border-right: 2px solid #dbdbdb">Total paiement</th>
          <th class="th-blue text-right">Dcptes antérieurs</th>
          <th class="th-blue text-right">Dcptes en attente de la période</th>
          <th class="th-blue text-right">Total en attente</th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row"
            v-for="(program,programKey) in decomptePer"
            :key="programKey">
          <!-- <td class="bg-info "
              id="first">  </td> -->
          <td class="text-left"
              style="border-right: 2px solid #dbdbdb">{{program.programme_libelle}}</td>
          <td class="text-right">{{Math.round(Number(program.stock_anterieure_n_1)).toLocaleString()}}</td>
          <td class="text-right"
              style="border-right: 2px solid #dbdbdb">{{Math.round(Number(program.stock_anterieure_n)).toLocaleString()}}</td>
          <td class="text-right"
              style="border-right: 2px solid #dbdbdb">{{Math.round(Number(program.decomptes_recus)).toLocaleString()}}</td>
          <td class="text-right"
              style="border-right: 2px solid #dbdbdb">{{Math.round(Number(program.total_decomptes_a_payer)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(program.decomptes_anterieurs)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(program.decomptes_recus_periode)).toLocaleString()}}</td>
          <td class="text-right"
              style="border-right: 2px solid #dbdbdb">{{Math.round(Number(program.total_paiement)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(program.decomptes_anterieurs_en_attentes)).toLocaleString() }}</td>
          <td class="text-right">{{Math.round(Number(program.decomptes_recus_en_attentes)).toLocaleString() }}</td>
          <td class="text-right">{{Math.round(Number(program.total_en_attente)).toLocaleString() }}</td>
          
        </tr>
      </tbody>
      <tfoot>
        <!-- Intergrer le total une fois disponible car on aura plus de pagination-->
        <tr scope="row"
            class="tht-blue">
          <!-- <td class="bg-info"></td> -->
          <td class="text-left text-uppercase"
              style="border-right: 2px solid #dbdbdb">Total</td>
          <td class="text-right">{{Math.round(Number(totaux.stock_anterieure_n_1)).toLocaleString()}}</td>
          <td class="text-right"
              style="border-right: 2px solid #dbdbdb">{{Math.round(Number(totaux.stock_anterieure_n)).toLocaleString()}}</td>
          <td class="text-right"
              style="border-right: 2px solid #dbdbdb">{{Math.round(Number(totaux.decomptes_recus)).toLocaleString()}}</td>
          <td class="text-right"
              style="border-right: 2px solid #dbdbdb">{{Math.round(Number(totaux.total_decomptes_a_payer)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(totaux.decomptes_anterieurs)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(totaux.decomptes_recus_periode)).toLocaleString()}}</td>
          <td class="text-right"
              style="border-right: 2px solid #dbdbdb">{{Math.round(Number(totaux.total_paiement)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(totaux.decomptes_anterieurs_en_attentes)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(totaux.decomptes_recus_en_attentes)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(totaux.total_en_attente)).toLocaleString()}}</td>
        </tr>
      </tfoot>
    </table>

  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex"

export default{
  name:"Per",
  props:["year"],
  data:() =>({
    decomptePer:[],
    index:1,
    totaux:{
      decomptes_anterieurs:0,
      decomptes_anterieurs_en_attentes: 0,
      decomptes_recus: 0,
      decomptes_recus_en_attentes: 0,
      decomptes_recus_periode: 0,
      stock_anterieure_n: 0,
      stock_anterieure_n_1: 0,
      total_decomptes_a_payer: 0,
      total_en_attente: 0,
      total_paiement: 0,
    }
  }),
  watch:{
    // TableauDecomptePer(){
    //   this.decomptePer = this.TableauDecomptePer.donnees
    //   // console.log(this.decomptePer)
    //   this.totaux.recu=0
    //   this.totaux.paye=0
    //   this.totaux.en_attente_payement=0
    //   this.totaux.en_attente_payement_anterieure=0
    //   this.TableauDecomptePer.donnees.forEach(per => {
    //     this.totaux.recu+= per.decompte_recu
    //     // console.log(this.totaux)
    //     this.totaux.paye+= per.decompte_paye
    //     this.totaux.en_attente_payement+= per.decompte_en_attente_payement
    //     this.totaux.en_attente_payement_anterieure+= per.decompte_en_attente_payement_anterieure
    //   })
    // },
    reportingDecompte(){
      if (this.reportingDecompte) {
        this.decomptePer = this.reportingDecompte.donnees
        this.totaux.decomptes_anterieurs_en_attentes= 0
        this.totaux.decomptes_anterieurs=0
        this.totaux.decomptes_recus= 0
        this.totaux.decomptes_recus_en_attentes= 0
        this.totaux.decomptes_recus_periode= 0
        this.totaux.stock_anterieure_n= 0
        this.totaux.stock_anterieure_n_1= 0
        this.totaux.total_decomptes_a_payer= 0
        this.totaux.total_en_attente= 0
        this.totaux.total_paiement= 0

        this.decomptePer.forEach(per => {
          this.totaux.decomptes_anterieurs= Number(per.decomptes_anterieurs)
          this.totaux.decomptes_anterieurs_en_attentes += Number(per.decomptes_anterieurs_en_attentes)
          this.totaux.decomptes_recus += Number(per.decomptes_recus)
          this.totaux.decomptes_recus_en_attentes += Number(per.decomptes_recus_en_attentes)
          this.totaux.decomptes_recus_periode += Number(per.decomptes_recus_periode)
          this.totaux.stock_anterieure_n += Number(per.stock_anterieure_n)
          this.totaux.stock_anterieure_n_1 += Number(per.stock_anterieure_n_1)
          this.totaux.total_decomptes_a_payer += Number(per.total_decomptes_a_payer)
          this.totaux.total_en_attente += Number(per.total_en_attente)
          this.totaux.total_paiement += Number(per.total_paiement)
        })
        console.log(this.decomptePer)
      }
    },
    year(){
      //this.getTableauDecomptePer({annee_debut:this.year.annee_debut,annee_fin:this.year.annee_fin,charge:"PER"})
      this.getReportingDecompte({annee_debut:this.year.annee_debut,annee_fin:this.year.annee_fin})

    }
  },
  created() {
    // this.getTableauDecomptePer({annee_debut:this.year.annee_debut,annee_fin:this.year.annee_fin,charge:"PER"})
    this.getReportingDecompte({annee_debut:this.year.annee_debut,annee_fin:this.year.annee_fin})
    console.log(this.year)
  },
  computed:{
    ...mapGetters(["TableauDecomptePer","reportingDecompte"])
  },
  methods:{
    ...mapActions(["getTableauDecomptePer","getReportingDecompte"])
  }
}
//TODO integrer le total une fois disponible car la pagination à été supprimée
</script>
<style>
</style>

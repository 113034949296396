<template>
  <div class="inner_container">
    <!-- <div class="row my-4">
      <div class="col-md-4 mx-auto">
        <div class="form-row row">
          <div class="form-group col-md-6"><label for="">Choisir l'année</label></div>
          <div class="form-group col-md-6">
            <select class="form-control"
                    name=""
                    id=""
                    v-model="year"
                    @change="$emit('annee', year)">
              <option :value="Number(year)-5">{{Number(year)-5}}</option>
              <option :value="Number(year)-4">{{Number(year)-4}}</option>
              <option :value="Number(year)-3">{{Number(year)-3}}</option>
              <option :value="Number(year)-2">{{Number(year)-2}}</option>
              <option :value="Number(year)-1">{{Number(year)-1}}</option>
              <option :value="year">{{year}}</option>
              <option :value="Number(year)+1">{{Number(year)+1}}</option>
              <option :value="Number(year)+2">{{Number(year)+2}}</option>
              <option :value="Number(year)+3">{{Number(year)+3}}</option>
              <option :value="Number(year)+4">{{Number(year)+4}}</option>
              <option :value="Number(year)+5">{{Number(year)+5}}</option>
            </select>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Analyse et graphique-->
    <ul class="nav nav-tabs justify-content-center"
        id="analysegraphique"
        role="tablist">
      <li class="nav-item">
        <a class="nav-link active"
           id="analyse-tab"
           data-toggle="tab"
           href="#analyse"
           role="tab"
           aria-controls="home"
           aria-selected="true"
           @click="ongletSelectionne='rubrique'">Analyse</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="graphique-tab"
           data-toggle="tab"
           href="#graphique"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="ongletSelectionne='graphique'">Graphique</a>
      </li>
    </ul>
    <div class="tab-content"
         id="myTabContent">
      <!-- Pour l'analyse-->
      <div class="tab-pane fade show active"
           id="analyse"
           role="tabpanel"
           aria-labelledby="home-tab">
        <Rubriques :year="year" 
                   v-if="ongletSelectionne=='rubrique'"/>
      </div>
      <!-- Pour le graphique-->
      <div class="tab-pane fade"
           id="graphique"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <Graphique :year="year" 
                   v-if="ongletSelectionne=='graphique'"/>
      </div>
    </div>
  </div>
</template>

<script>
import Rubriques from "./Rubriques.vue"
import Graphique from "./Graphique.vue"

export default {
  name: "VueGlobale",
  components: {
    Rubriques,
    Graphique
  },
  data: () => ({
    year: {
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",  
    },
    ongletSelectionne:"rubrique",
  }),
  props:["extYear"],
  watch:{
    extYear(){
      if (this.extYear) {
        this.changeYear(this.extYear)
      }
    }
  },
  created(){
    this.changeYear(this.extYear)
  },
  methods: {
    changeYear(year) {
      this.year=year
    },
  }
}
</script>

<template>
  <div class="inner_container">
    
    <!-- Analyse et graphique-->
    <ul class="nav nav-tabs justify-content-center"
        id="analysegraphique"
        role="tablist">
      <li class="nav-item">
        <a class="nav-link active"
           id="per-tab"
           data-toggle="tab"
           href="#per"
           role="tab"
           aria-controls="home"
           aria-selected="true"
           @click="selection('PER')">Synthèse</a>
      </li>
      
      <!-- <li class="nav-item">
        <a class="nav-link"
           id="stock-tab"
           data-toggle="tab"
           href="#stock"
           role="tab"
           aria-controls="profile"
           aria-selected="false">Stock</a>
      </li> -->
     
      <li class="nav-item">
        <a class="nav-link"
           id="synthese-tab"
           data-toggle="tab"
           href="#synthese"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="selection('synthese')">Stock</a>
      </li>
    </ul>
    <div class="tab-content" 
         id="myTabContent">
      <!-- Pour PER-->
      <div class="tab-pane fade show active" 
           id="per"
           role="tabpanel"
           aria-labelledby="home-tab"
           v-if="charge='PER'">
        <Per :year="year"/>

      </div>
      <!-- Pour le Stock-->
      <!--
      <div class="tab-pane fade" 
           id="stock"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <Stock />
      </div>
      -->
      <!-- Pour la Synthese-->
      <div class="tab-pane fade" 
           id="synthese"
           role="tabpanel"
           aria-labelledby="profile-tab"
           v-if="charge='synthese'">
        <Synthese :year="year"/>
      </div>

    </div>
  </div>
</template>
<script> 
import Per from './Per.vue'
//import Stock from './Stock.vue'
import Synthese from './Synthese.vue'
export default {
  
  name : "Decomptes",
  components:{
    Per,
    // Stock,
    Synthese
  },
  data: () =>({
    year: {
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",  
    },
    charge:"PER",
  }),
  props:["extYear"],
  watch:{
    extYear(){
      if (this.extYear) {
        this.changeYear(this.extYear)
      }
    }
  },
  created(){
    this.year=this.extYear
  },
  methods: {
    changeYear(year) {
      this.year=year
    },
    selection(key){
      this.charge=""
      var save=this.year
      this.year=""
      setTimeout(
        function() {
          this.charge = key
          this.year=save
        }.bind(this),
        500
      )
    }
  }
}
</script>
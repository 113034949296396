<template>
  <div class="inner_container">
    
    <div class="row mt-4">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>
    <div class="row mb-4">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Base: </label>
          <select class="form-control mx-2"
                  name="" 
                  id=""
                  v-model="year.base"
                  @change="changeYear(year)">
            <option value="SIEGE">Siège</option>
            <option value="PEAGE">Péage</option>
            <option value="PESAGE">Pesage</option>
            <option value="AIRE_STATIONNEMENT">Aire de stationnement</option>
            <option value="CONSOLIDEE">Consolidée</option>
          </select>
        </div>
      </form>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////// ZONE IMPRIMABLE ///////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  filename="Situation_des_etats_decaissables"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                  v-if="loaded==true">
      <div slot="pdf-content">
        <div class="row justify-content-center">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Situation des emplois décaissables du {{ annee.debut }} au {{ annee.fin }}
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-11">
            <table class="table table-blue mt-4">
              <thead v-if="etatMensuel!=''">
                <tr>
                  <th class="tht-blue"
                      style="width: 13%;">NATURE DES EMPLOIS</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[0]">Janvier</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[1]">Février</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[2]">Mars</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[3]">Avril</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[4]">Mai</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[5]">Juin</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[6]">Juillet</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[7]">Août</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[8]">Septembre</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[9]">Octobre</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[10]">Novembre</th>
                  <th class="tht-blue text-center"
                      v-if="monthLabel[11]">Décembre</th>
                  <th class="tht-blue text-center">TOTAL</th>
                </tr>
              </thead>
              <thead v-if="etatAnnuel!=''">
                <tr>
                  <th class="tht-blue"
                      style="width: 13%;">Libellé</th>

                  <template v-for="(annee,akey) in etatAnnuel[0].periode">
                    <th class="tht-blue text-center"
                        :key="akey">{{annee.annee}}</th>
                  </template>

                  <th class="tht-blue text-center">TOTAL</th>
                </tr>
              </thead>
              <tbody v-if="year.periode=='MENSUELLE'">
                <template v-for="(ressource,reskey) in  etatMensuel">
                  <template v-for="(niveau4, nivkey) in ressource.niveau_4">
                    <tr :key="'mois'+nivkey+'.'+reskey">
                      <td class="tht-blue">{{ niveau4.libelle }}</td>
                      <td v-for="(montant,montkey) in niveau4.montants"
                          :key="montkey"
                          class="text-right">{{ Math.round(Number(montant)).toLocaleString() }}</td>
                    </tr>
                  </template>
                  <tr :key="'moisTitle'+reskey">
                    <td class="tht-dark-blue">{{ ressource.libelle }}</td>
                    <td v-for="(total,totkey) in ressource.total_n3"
                        :key="totkey" 
                        class="tht-dark-blue text-right">{{ Math.round(Number(total)).toLocaleString() }}</td>
                  
                  </tr>
                </template>
              </tbody>

              <!-- AFFICHAGE ANNUEL -->

              <tbody v-if="year.periode=='ANNUELLE'">
                <template v-for="(ressource,reskey) in  etatAnnuel">
                  <template v-for="(niveau4, nivkey) in ressource.niveau_4">
                    <tr :key="'an'+nivkey+'.'+reskey">
                      <td class="tht-blue">{{ niveau4.libelle }}</td>
                      <td v-for="(montant,montkey) in niveau4.montants"
                          :key="montkey"
                          class="text-right">{{ Math.round(Number(montant)).toLocaleString() }}</td>
                    </tr>
                  </template>
                  <tr :key="'anTitle'+reskey">
                    <td class="tht-dark-blue">{{ ressource.libelle }}</td>
                    <td v-for="(total,totkey) in ressource.total_n3"
                        :key="totkey" 
                        class="tht-dark-blue text-right">{{ Math.round(Number(total)).toLocaleString() }}</td>
                  
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </vue-html2pdf>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <div class="row">
      <div class="col-md-8 mx-auto">
        <h5 class="text-center"
            v-show="loading">
          Chargement des données en cours...
        </h5>
        <Loader v-show="loading" />
      </div>
    </div>
    <table class="table table-blue mt-4"
           v-if="!loading">
      <thead v-if="year.periode=='MENSUELLE'">
        <tr>
          <th class="tht-blue"
              style="width: 13%;">NATURE DES RESSOURCES</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[0]">Janvier</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[1]">Février</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[2]">Mars</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[3]">Avril</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[4]">Mai</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[5]">Juin</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[6]">Juillet</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[7]">Août</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[8]">Septembre</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[9]">Octobre</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[10]">Novembre</th>
          <th class="tht-blue text-center"
              v-if="monthLabel[11]">Décembre</th>
          <th class="tht-blue text-center">TOTAL</th>
        </tr>
      </thead>
      <!-- AFFICHAGE ANNUEL -->
      <thead v-if="year.periode=='ANNUELLE'">
        <tr>
          <th class="tht-blue"
              style="width: 13%;">NATURE DES RESSOURCES</th>
          <template v-for="(annee,akey) in sous_total[0].etatAnnuel[0].periode">
            <th class="tht-blue text-center"
                :key="'n3-'+akey">{{annee}}</th>
          </template>
          <th class="tht-blue text-center">TOTAL</th>
        </tr>
      </thead>
      <template v-if="year.periode=='MENSUELLE' && loaded" >
        <tbody v-for="(stotal,stkey) in sous_total"
               :key="stkey">
          <template v-for="(ressource,reskey) in  stotal.etatMensuel">
            <template v-for="(niveau4, nivkey) in ressource.niveau_4">
              <tr :key="'n4-'+niveau4.libelle+nivkey">
                <td class="tht-blue">{{ niveau4.libelle }}</td>
                <td v-for="(montant,montkey) in niveau4.montants"
                    :key="montkey"
                    class="text-right">{{ Math.round(Number(montant)).toLocaleString() }}</td>
              </tr>
            </template>
            <tr :key="'title'+reskey">
              <td class="tht-dark-blue">{{ ressource.libelle }}</td>
              <td v-for="(total,totkey) in ressource.total_n3"
                  :key="'tot-'+totkey" 
                  class="text-right tht-dark-blue">{{ Math.round(Number(total)).toLocaleString() }}</td>
            </tr>
          </template>
          <!-- <tr v-if="year.base=='CONSOLIDEE'">
            <td class="tht-blue">SOUS-TOTAL {{ stotal.base }}</td>
            <td v-for="(stmont,stmkey) in stotal.montants"
                :key="stmkey"
                class="text-right tht-blue">{{ Math.round(stmont).toLocaleString() }}</td>
          </tr> -->
        </tbody>
      </template>
      
      <!-- AFFICHAGE ANNUEL -->
      <template v-if="year.periode=='ANNUELLE' && loaded" >
        <tbody v-for="(stotal,stkey) in sous_total"
               :key="stkey">
          <template v-for="(ressource,reskey) in  stotal.etatAnnuel">
            <template v-for="(niveau4, nivkey) in ressource.niveau_4">
              <tr :key="nivkey"
                  v-if="year.base!='CONSOLIDEE'">
                <td class="tht-blue">{{ niveau4.libelle }}</td>
                <td v-for="(montant,montkey) in niveau4.montants"
                    :key="montkey"
                    class="text-right">{{ Math.round(Number(montant)).toLocaleString() }}</td>
              </tr>
            </template>
            <tr :key="'title'+reskey">
              <td :class="year.base=='CONSOLIDEE'?'':'tht-dark-blue'">{{ ressource.libelle }}</td>
              <td v-for="(total,totkey) in ressource.total_n3"
                  :key="totkey" 
                  class="text-right"
                  :class="year.base=='CONSOLIDEE'?'':'tht-dark-blue'">{{ Math.round(Number(total)).toLocaleString() }}</td>
            </tr>
          </template>
          <!-- <tr v-if="year.base=='CONSOLIDEE'">
            <td class="tht-blue">SOUS-TOTAL {{ stotal.base }}</td>
            <td v-for="(stmont,stmkey) in stotal.montants"
                :key="stmkey"
                class="text-right tht-blue">{{ Math.round(stmont).toLocaleString() }}</td>
          </tr> -->
        </tbody>
      </template>
      
      <!-- AFFICHAGE TOTAL GENERAL -->
      <tfoot>
        <tr>
          <td class="tht-dark-blue">TOTAL GENERAL</td>
          <td v-for="(general,genkey) in  total_general"
              :key="genkey"
              class="tht-dark-blue text-right">{{ Math.round(Number(general)).toLocaleString() }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import VueHtml2pdf from 'vue-html2pdf'
import Loader from "@/components/shared/Spinner"
export default {
  name: "EngagementFinanciere",
  components:{
    VueHtml2pdf,
    Loader
  },
  props:["extYear"],
  data: () => ({
    year: {
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
      periode:"MENSUELLE",
      base:"CONSOLIDEE"  
    },
    annee:{
      debut:"",
      fin:""
    },
    loading:true,
    etatMensuel:[],
    etatAnnuel:"",
    monthLabel:[false,false,false,false,false,false,false,false,false,false,false,false,],
    long:0,
    loaded:false,
    totaux:{
      per:[0,0,0,0,0,0,0,0,0,0,0,0,0],
      peage:[0,0,0,0,0,0,0,0,0,0,0,0,0],
      pesage:[0,0,0,0,0,0,0,0,0,0,0,0,0],
      fonctionnement:[0,0,0,0,0,0,0,0,0,0,0,0,0],
      sous_total:[0,0,0,0,0,0,0,0,0,0,0,0,0],
      remboursement:[0,0,0,0,0,0,0,0,0,0,0,0,0],
      general:[0,0,0,0,0,0,0,0,0,0,0,0,0],
    },
    sous_total:[],
    total_general:[]
  }),
  created() {
    this.year.annee_debut=this.extYear.annee_debut
    this.year.annee_fin=this.extYear.annee_fin
    var fin_annee=this.extYear.annee_fin.split("-")
    var debut_annee=this.extYear.annee_debut.split("-")
    console.log(debut_annee)
    console.log(this.extYear.annee_fin)
    if (fin_annee[0]==debut_annee[0]) {
      this.year.periode="MENSUELLE"
    }else{
      this.year.periode="ANNUELLE"
    }
    this.loadEtatDecaissables(this.year)
  },
  watch: {
    //TODO la requette renvoi une erreur 500 lorseque l'annee 2021 change
    extYear(){
      if (this.extYear) {
        console.log(this.extYear)
        this.changeYear(this.extYear)
      }
    },
    etatDecaissable(){
      if (this.etatDecaissable) {
        // console.log(this.etatDecaissable)
        this.loaded=true
        if(this.year.periode=="MENSUELLE"){
          var fin_annee=this.year.annee_fin.split("-")
          var debut_annee=this.year.annee_debut.split("-")
          this.annee.debut= debut_annee[2]+"-"+debut_annee[1]+"-"+debut_annee[0]
          this.annee.fin= fin_annee[2]+"-"+fin_annee[1]+"-"+fin_annee[0]
          for (let index = debut_annee[1]-1; index < fin_annee[1]; index++) {
            this.monthLabel[index]=true            
          }
          this.etatMensuel=[]
          if (this.year.base == 'CONSOLIDEE') {
            this.sous_total=[
              {base:"SIEGE",montants:[],etatMensuel:[]},
              {base:"PEAGE",montants:[],etatMensuel:[]},
              {base:"PESAGE",montants:[],etatMensuel:[]},
              {base:"AIRE_STATIONNEMENT",montants:[],etatMensuel:[]},
            ]
          } else {
            this.sous_total=[
              {base:this.year.base,montants:[],etatMensuel:[]},
            ]
          }
          
          for (let indexer = 0; indexer < this.sous_total.length; indexer++) {
            var count=0
            this.etatDecaissable.niveau_3.forEach(niveau3 => {
              if (niveau3.libelle.split(" ")[0]=="REMBOURSEMENT") {
                if (this.year.base == "CONSOLIDEE" && (this.sous_total[indexer].base==niveau3.base)) {
                  this.sous_total[indexer].etatMensuel.push({libelle:niveau3.libelle,base:niveau3.base,niveau_4:[],total_n3:[]})
                  var counter=0
                  niveau3.niveau_4.forEach(element => {
                    this.sous_total[indexer].etatMensuel[count].niveau_4.push({libelle:element.libelle,montants:[]})
                    var total_n4=0
                    for (let index = debut_annee[1]-1; index < fin_annee[1]; index++) {
                      this.sous_total[indexer].etatMensuel[count].niveau_4[counter].montants.push(element.montants[0][index])
                      total_n4+=element.montants[0][index]
                    }
                    this.sous_total[indexer].etatMensuel[count].niveau_4[counter].montants.push(total_n4)
                    counter++
                  })
                  for (let index = 0; index < this.sous_total[indexer].etatMensuel[count].niveau_4[0].montants.length; index++) {
                    this.sous_total[indexer].etatMensuel[count].total_n3.push(0)
                  }
                
                  this.sous_total[indexer].etatMensuel[count].niveau_4.forEach(niveau4 => {
                    for (let index = 0; index < niveau4.montants.length; index++) {
                      this.sous_total[indexer].etatMensuel[count].total_n3[index]+=niveau4.montants[index]                
                    }
                  }) 
                  count++
                } else {
                  if (this.year.base==niveau3.base) {
                    this.sous_total[indexer].etatMensuel.push({libelle:niveau3.libelle,base:niveau3.base,niveau_4:[],total_n3:[]})
                    counter=0
                    niveau3.niveau_4.forEach(element => {
                      console.log(count)
                      this.sous_total[indexer].etatMensuel[count].niveau_4.push({libelle:element.libelle,montants:[]})
                      var total_n4=0
                      for (let index = debut_annee[1]-1; index < fin_annee[1]; index++) {
                        this.sous_total[indexer].etatMensuel[count].niveau_4[counter].montants.push(element.montants[0][index])
                        total_n4+=element.montants[0][index]
                      }
                      this.sous_total[indexer].etatMensuel[count].niveau_4[counter].montants.push(total_n4)
                      // console.log(counter)
                      counter++
                    })
                    for (let index = 0; index < this.sous_total[indexer].etatMensuel[count].niveau_4[0].montants.length; index++) {
                      this.sous_total[indexer].etatMensuel[count].total_n3.push(0)
                    }
                    this.sous_total[indexer].etatMensuel[count].niveau_4.forEach(niveau4 => {
                      for (let index = 0; index < niveau4.montants.length; index++) {
                        this.sous_total[indexer].etatMensuel[count].total_n3[index]+=niveau4.montants[index]                
                      }
                    })
                    count++
                  }
                }
              }
            }) 
          }
          console.log(this.sous_total)

          // INITIALISATION DU NOMBRE DE COLONNE DU TABLEAU DES SOUS-TOTAUX
          for (let indexer = 0; indexer < this.sous_total.length; indexer++) {
            for (let index = 0; index < this.sous_total[indexer].etatMensuel[0].total_n3.length; index++) {
              this.sous_total[indexer].montants.push(0)
            }
          }
          // ALIMENTATION DU TABLEAU DES SOUS-TOTAUX
          for (let indexer = 0; indexer < this.sous_total.length; indexer++) {
            this.sous_total[indexer].etatMensuel.forEach(niveau3 => {
              if (niveau3.base==this.sous_total[indexer].base) {
                for (let index = 0; index < niveau3.total_n3.length; index++) {
                  this.sous_total[indexer].montants[index]+=niveau3.total_n3[index]
                }
              }
            })
          }
          // INITIALISATION DU NOMBRE DE COLONNE DU TABLEAU DU TOTAL GENERAL
          this.total_general=[]
          for (let index = 0; index < this.sous_total[0].etatMensuel[0].niveau_4[0].montants.length; index++) {
            this.total_general.push(0)
          }
          // ALIMENTATION DU TABLEAU DU TOTAL GENERAL
          for (let indexer = 0; indexer < this.sous_total.length; indexer++) {
            this.sous_total[indexer].etatMensuel.forEach(niveau3 => {
              niveau3.niveau_4.forEach(niveau4 => {
                for (let index = 0; index < niveau4.montants.length; index++) {
                  this.total_general[index] += niveau4.montants[index]
                }
              })
            })
          }
          console.log(this.sous_total)
        }else{
          this.etatAnnuel=[]
          if (this.year.base == 'CONSOLIDEE') {
            this.sous_total=[
              {base:"SIEGE",montants:[],etatAnnuel:[]},
              {base:"PEAGE",montants:[],etatAnnuel:[]},
              {base:"PESAGE",montants:[],etatAnnuel:[]},
              {base:"AIRE_STATIONNEMENT",montants:[],etatAnnuel:[]},
            ]
          } else {
            this.sous_total=[
              {base:this.year.base,montants:[],etatAnnuel:[]},
            ]
          }
          for (let indexer = 0; indexer < this.sous_total.length; indexer++) {
            count=0
            this.etatDecaissable.niveau_3.forEach(niveau3 => {
              if (niveau3.libelle.split(" ")[0]=="REMBOURSEMENT") {
                if (this.year.base == "CONSOLIDEE" && (this.sous_total[indexer].base == niveau3.base)) {
                  this.sous_total[indexer].etatAnnuel.push({libelle:niveau3.libelle,base:niveau3.base,niveau_4:[],total_n3:[],periode:""})
                  var counter=0
                  niveau3.niveau_4.forEach(element => {
                    this.sous_total[indexer].etatAnnuel[count].niveau_4.push({libelle:element.libelle,montants:[]})
                    this.sous_total[indexer].etatAnnuel[count].periode = element.annees[0]
                    var total_n4=0
                    for (let index = 0; index < element.montants[0].length; index++) {
                      this.sous_total[indexer].etatAnnuel[count].niveau_4[counter].montants.push(element.montants[0][index])
                      total_n4+=element.montants[0][index]
                    }
                    this.sous_total[indexer].etatAnnuel[count].niveau_4[counter].montants.push(total_n4)
                    counter++
                  })
                  for (let index = 0; index < this.sous_total[indexer].etatAnnuel[count].niveau_4[0].montants.length; index++) {
                    this.sous_total[indexer].etatAnnuel[count].total_n3.push(0)
                  }
                  this.sous_total[indexer].etatAnnuel[count].niveau_4.forEach(niveau4 => {
                    for (let index = 0; index < niveau4.montants.length; index++) {
                      this.sous_total[indexer].etatAnnuel[count].total_n3[index]+=niveau4.montants[index]                
                    }
                  })
                  count++
                } else {
                  if (this.year.base==niveau3.base) {
                    console.log(niveau3.base)
                    this.sous_total[indexer].etatAnnuel.push({libelle:niveau3.libelle,base:niveau3.base,niveau_4:[],total_n3:[],periode:""})
                    counter=0
                    niveau3.niveau_4.forEach(element => {
                      this.sous_total[indexer].etatAnnuel[count].niveau_4.push({libelle:element.libelle,montants:[]})
                      this.sous_total[indexer].etatAnnuel[count].periode = element.annees[0]
                      var total_n4=0
                      for (let index = 0; index < element.montants[0].length; index++) {
                        this.sous_total[indexer].etatAnnuel[count].niveau_4[counter].montants.push(element.montants[0][index])
                        total_n4+=element.montants[0][index]
                      }
                      this.sous_total[indexer].etatAnnuel[count].niveau_4[counter].montants.push(total_n4)
                      counter++
                    })
                    for (let index = 0; index < this.sous_total[indexer].etatAnnuel[count].niveau_4[0].montants.length; index++) {
                      this.sous_total[indexer].etatAnnuel[count].total_n3.push(0)
                    }
                    this.sous_total[indexer].etatAnnuel[count].niveau_4.forEach(niveau4 => {
                      for (let index = 0; index < niveau4.montants.length; index++) {
                        this.sous_total[indexer].etatAnnuel[count].total_n3[index]+=niveau4.montants[index]                
                      }
                    })
                    count++
                  }
                }
              }
            })
          }
          console.log(this.sous_total)
          // INITIALISATION DU NOMBRE DE COLONNE DU TABLEAU DES SOUS-TOTAUX
          for (let indexer = 0; indexer < this.sous_total.length; indexer++) {
            if (this.sous_total[indexer].etatAnnuel.length >0) {
              for (let index = 0; index < this.sous_total[indexer].etatAnnuel[0].total_n3.length; index++) {
                this.sous_total[indexer].montants.push(0)
              }
            }
          }
          // ALIMENTATION DU TABLEAU DES SOUS-TOTAUX
          for (let indexer = 0; indexer < this.sous_total.length; indexer++) {
            this.sous_total[indexer].etatAnnuel.forEach(niveau3 => {
              console.log(niveau3)
              if (niveau3.base==this.sous_total[indexer].base) {
                console.log("in")
                for (let index = 0; index < niveau3.total_n3.length; index++) {
                  console.log(niveau3.total_n3[index])
                  this.sous_total[indexer].montants[index]+=niveau3.total_n3[index]
                }
              }
            })
          }
          // INITIALISATION DU NOMBRE DE COLONNE DU TABLEAU DU TOTAL GENERAL
          this.total_general=[]
          for (let index = 0; index < this.sous_total[0].etatAnnuel[0].niveau_4[0].montants.length; index++) {
            this.total_general.push(0)
          }
          // ALIMENTATION DU TABLEAU DU TOTAL GENERAL
          for (let indexer = 0; indexer < this.sous_total.length; indexer++) {
            this.sous_total[indexer].etatAnnuel.forEach(niveau3 => {
              niveau3.niveau_4.forEach(niveau4 => {
                for (let index = 0; index < niveau4.montants.length; index++) {
                  this.total_general[index] += niveau4.montants[index]
                }
              })
            })
          }
          
          console.log(this.sous_total)
        }
        this.loading=false
      }
        
    }
  },
  computed: {
    ...mapGetters(["etatDecaissable","msgFailEtatDecaissable"])
  },
  methods: {
    ...mapActions(["loadEtatDecaissables"]),
    ...mapMutations(["setEtatDecaisssable","setMsgFailEtatDecaisssable"]),
    changeYear(year) {
      this.year.annee_debut=year.annee_debut
      this.year.annee_fin=year.annee_fin
      var fin_annee=year.annee_fin.split("-")
      var debut_annee=year.annee_debut.split("-")
      
      if (fin_annee[0]==debut_annee[0]) {
        this.year.periode="MENSUELLE"
      }else{
        this.year.periode="ANNUELLE"
      }
      console.log(this.year)
      this.loaded=false
      this.loading=true
      this.loadEtatDecaissables(this.year)
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    }
  }
}
</script>

<template>
  <div class="col-md-7 mx-auto mt-4">
    <h5 class="text-center"
        v-show="loading">
      Chargement des données en cours...
    </h5>
    <Loader v-show="loading" />
                  
    <table class="table table-blue"
           v-if="!loading"> 
      <thead>
        <tr>
          <th class="th-blue"
              scope="col">Rubriques</th>
          <th class="th-blue"
              scope="col">Totaux (FCFA)</th>
        </tr>
      </thead>
      
      <tbody>
        <tr v-for="(etat,etatkey) in etatMensuel"
            :key="etatkey">
          <td>{{ etat.libelle }}</td>
          <td class="text-right">{{ Math.round(Number(etat.total_n3[etat.total_n3.length-1])).toLocaleString() }}</td>
        </tr>
        <tr scope="row"
            class="tht-blue">
          <td>Total</td>
          <td class="text-right uppercase">{{Math.round(Number(total)).toLocaleString()}}</td>
        </tr>

      </tbody>
      <!-- <tbody v-else>
        <tr scope="row">
          <td class="text-center">Aucune donnée disponible...</td>
        </tr>
      </tbody> -->
    </table>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex"
import Loader from "@/components/shared/Spinner"

export default {
  name : "Rubriques",
  props:['year'],
  components: {
    Loader,
  },
  data:() =>({
    donneescharges:[],
    total:0,
    annee: {
      annee_debut: "",
      annee_fin: "",
      periode:"MENSUELLE",
      base:"CONSOLIDEE"  
    },
    loading:true,
    totaux:{
      avant_per:[0],
      per:[0],
      peage:[0],
      pesage:[0],
      fonctionnement:[0],
      sous_total:[0],
      remboursement:[0],
      general:[0],
    },
    etatMensuel:[],
    etatAnnuel:"",
  }),
  watch:{
    TableauCharges(){
      this.total=""
      this.donneescharges = this.TableauCharges.donnees
      this.total=Number(this.donneescharges[0].remboursement)+
        Number(this.donneescharges[0].depense_peage)+Number(this.donneescharges[0].fonctionnement)+
        Number(this.donneescharges[0].autres_depense_assimiles)+Number(this.donneescharges[0].depense_pesage)+
        Number(this.donneescharges[0].per_decompte_recu)
    },
    etatDecaissable(){
      if (this.etatDecaissable) {
        // console.log(this.etatDecaissable)
        this.total =0
        if(this.annee.periode=="MENSUELLE"){
          var fin_annee=this.annee.annee_fin.split("-")
          var debut_annee=this.annee.annee_debut.split("-")
          this.etatMensuel=[]
          var count=0
          this.etatDecaissable.niveau_3.forEach(niveau3 => {
            if(niveau3.libelle.split(" ")[0]!="DETTES"){
              this.etatMensuel.push({libelle:niveau3.libelle,niveau_4:[],total_n3:[]})
              var counter=0
              niveau3.niveau_4.forEach(element => {
                this.etatMensuel[count].niveau_4.push({libelle:element.libelle,montants:[]})
                var total_n4=0
                for (let index = debut_annee[1]-1; index < fin_annee[1]; index++) {
                  this.etatMensuel[count].niveau_4[counter].montants.push(element.montants[0][index])
                  total_n4+=element.montants[0][index]
                }
                this.etatMensuel[count].niveau_4[counter].montants.push(total_n4)
                counter++
              })
              for (let index = 0; index < this.etatMensuel[count].niveau_4[0].montants.length; index++) {
                this.etatMensuel[count].total_n3.push(0)
              }
              this.etatMensuel[count].niveau_4.forEach(niveau4 => {
                for (let index = 0; index < niveau4.montants.length; index++) {
                  this.etatMensuel[count].total_n3[index]+=niveau4.montants[index]                
                }
                this.total +=this.etatMensuel[count].total_n3[niveau4.montants.length-1]
              })
              count++
            }
          })
          console.log(this.etatMensuel)
        }else{
          this.etatAnnuel=[]
          count=0
          this.etatDecaissable.niveau_3.forEach(niveau3 => {
            if (niveau3.libelle.split(" ")[0]!="DETTES") {
              this.etatAnnuel.push({libelle:niveau3.libelle,niveau_4:[],total_n3:[],periode:""})
              var counter=0
              niveau3.niveau_4.forEach(element => {
                this.etatAnnuel[count].niveau_4.push({libelle:element.libelle,montants:[]})
                this.etatAnnuel[count].periode = element.annees[0]
                var total_n4=0
                for (let index = 0; index < element.montants[0].length; index++) {
                  this.etatAnnuel[count].niveau_4[counter].montants.push(element.montants[0][index])
                  total_n4+=element.montants[0][index]
                }
                this.etatAnnuel[count].niveau_4[counter].montants.push(total_n4)
                counter++
              })
              for (let index = 0; index < this.etatAnnuel[count].niveau_4[0].montants.length; index++) {
                this.etatAnnuel[count].total_n3.push(0)
              }
              this.etatAnnuel[count].niveau_4.forEach(niveau4 => {
                for (let index = 0; index < niveau4.montants.length; index++) {
                  this.etatAnnuel[count].total_n3[index]+=niveau4.montants[index]                
                }
                this.total+=this.etatAnnuel[count].total_n3[niveau4.montants.length-1]

              })
              count++
            }
          })
          console.log(this.etatAnnuel)
        }
        this.loading=false 
      }
        
    },
    year(){
      // this.getTableauCharges(this.annee)
      this.loading=true
      this.loadEtatDecaissables(this.annee)
    }
  },
  created() {
    this.annee.annee_debut=this.year.annee_debut
    this.annee.annee_fin=this.year.annee_fin
    var fin_annee=this.year.annee_fin.split("-")
    var debut_annee=this.year.annee_debut.split("-")
    if (fin_annee[0]==debut_annee[0]) {
      this.annee.periode="MENSUELLE"
    }else{
      this.annee.periode="ANNUELLE"
    }
    // this.getTableauCharges(this.annee)
    this.loadEtatDecaissables(this.annee)

  },
  computed:{
    ...mapGetters(["TableauCharges","etatDecaissable","msgFailEtatDecaissable"])
  },
  methods:{
    ...mapActions(["getTableauCharges","loadEtatDecaissables"]),
    ...mapMutations(["setEtatDecaisssable","setMsgFailEtatDecaisssable"])
  }

}
</script>
<template>
  <div class="mx-auto mt-2">
    <table class="table table-hover"> 
      <thead>
        <tr>
          <th scope="col"
              class="tht-blue">Date comptable</th>
          <th scope="col"
              class="tht-blue">Programme</th>
          <th scope="col"
              class="tht-blue">Délais de présence</th>
          <th scope="col"
              class="tht-blue">Entreprise</th>
          <th scope="col"
              class="tht-blue">N° Marché</th>
          <th scope="col"
              class="tht-blue">Libellé</th>
          <th scope="col"
              class="tht-blue text-right">Montant Décompte/Facture</th>
          <th scope="col"
              class="tht-blue text-right">Règlement Effectué</th>
          <th scope="col"
              class="tht-blue text-right">Solde</th>
        </tr>
      </thead>
      <tbody v-if="decompteSythese != null">
        <tr v-for="(decompte, deckey) in decompteSythese"
            :key="deckey">
          <td>{{decompte.date_comptable}}</td>
          <td>{{decompte.nom_programme}}</td>
          <td>{{decompte.delais_presence}} jour(s)</td>
          <td>{{decompte.libelle_entreprise}}</td>
          <td>{{decompte.numero_marche}}</td>
          <td>{{decompte.numero_decompte}}</td>
          <td class="text-right">{{Math.round(Number(decompte.montant_facture_decompte))}}</td>
          <td class="text-right">{{Math.round(Number(decompte.reglement_effectue)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(decompte.solde)).toLocaleString()}}</td>
        </tr>  
      </tbody>
      <!--LE ELSE SI AUCUNE DONNEE RECU -->
      <tbody v-else>
        <tr>
          <td>Aucune donnee trouvée</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="6"
              class="tht-dark-blue">TOTAL</td>
          <td class="text-right tht-dark-blue">{{Math.round(Number(total.montant_facture_decompte))}}</td>
          <td class="text-right tht-dark-blue">{{Math.round(Number(total.reglement_effectue)).toLocaleString()}}</td>
          <td class="text-right tht-dark-blue">{{Math.round(Number(total.solde)).toLocaleString()}}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex"

export default{
  name:"Synthese",
  props:["year"],
  data:() =>({
    decompteSythese:null,
    total:{
      montant_facture_decompte:0,
      reglement_effectue:0,
      solde:0,
    }
  }),
  watch:{
    TableauDecompteSynthese(){
      this.decompteSythese = this.TableauDecompteSynthese.donnees
      this.total.montant_facture_decompte = 0
      this.total.reglement_effectue = 0
      this.total.solde = 0
      this.decompteSythese.forEach(decompte => {
        this.total.montant_facture_decompte += decompte.montant_facture_decompte
        this.total.reglement_effectue += decompte.reglement_effectue
        this.total.solde += decompte.solde
      })      
    },
    year(){
      this.getTableauDecompteSynthese({annee_debut:this.year.annee_debut,annee_fin:this.year.annee_fin})
    }
  },
  created() {
    //this.decomptePer = this.TableauDecomptePer.donnees
    this.getTableauDecompteSynthese({annee_debut:this.year.annee_debut,annee_fin:this.year.annee_fin})
  },
  computed:{
    ...mapGetters(["TableauDecompteSynthese"])
  },
  methods:{
    //@vuese
    //La recuperation du tableau de données
    ...mapActions(["getTableauDecompteSynthese"])
  }
}
</script>